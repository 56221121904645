.new-profile-main-container
{
	width: 100%;
	height: 100%;
}
.new-profile-title
{
	color: #202123;
	top: 29px;
	left: 30px;
	position: relative;
	
	font-size: 19px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.new-profile-subtitle
{
	color: #434D5F;
	top: 35px;
	display: flex;
	left: 30px;
	width: 800px;
	position: relative;
	
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}
.new-profile-input-containers
{
	display: flex;
	position: relative;
	flex-direction: column;
	left: 30px;
	top: 50px;
	gap: 26px;

}
.new-profile-element-div
{
	display: flex;
	gap: 22px;
	align-items: center;
	
}
.new-profile-element-span
{
	color: #616E88;
text-align: right;

font-size: 13px;
font-style: normal;
font-weight: 500;
width: 75px;
line-height: 18px;
}

.new-profile-element-input
{
	display: flex;
	width: 390px;
	height: 33.6px;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #C0C6CC;
	background: #FFF;
	outline: none;
}
.new-profile-desc-input
{
	display: flex;
	max-width: 390px;
	min-width: 390px;
	min-height: 33.6px;
	max-height: 100px;
	align-items: center;
	border-radius: 4px;
	outline: none;
	border: 1px solid #C0C6CC;
	background: #FFF;
}
.new-profile-btn-div
{
	display: flex;
	align-items: flex-start;
	gap: 10px;
	position: relative;
	left: 30px;
	top: 60px;
}
.new-profile-cancel-btn
{
	display: flex;
	padding: 6.8px 20.8px 7.8px 20.8px;
	align-items: flex-start;
	border-radius: 6px;
	border: 1px solid #D0D4DF;
	background: linear-gradient(180deg, #FCFDFF 0%, #EAEEF5 100%);
	box-shadow: 0px -1px 0px 1px #D0D4DF inset;
	color: #313949;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.new-profile-save-btn
{
	display: flex;
min-width: 31px;
padding: 8px 20.6px 8px 20.8px;
align-items: flex-start;
border-radius: 6px;
	border: 1px solid #D0D4DF;
	background: #1AA7EC;
	box-shadow: 0px -1px 0px 1px #D0D4DF inset;
	color: #313949;
color: #FFF;
text-align: center;

font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}