.roles-main-container
{
	width: 100%;
	height: 100%;
}
.roles-title
{
	color: #202123;
	top: 29px;
	left: 30px;
	position: relative;
	
	font-size: 19px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.roles-subtitle
{
	color: #434D5F;
	top: 35px;
	display: flex;
	left: 30px;
	width: 800px;
	position: relative;
	
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}

.roles-add-role-btn
{
	display: inline-flex;
	left: 30px;
	top: 50px;
	position: relative;
	min-width: 31px;
	padding: 8px 21.3px 8px 21.5px;
	align-items: flex-start;
	border-radius: 6px;
	border: 1px solid #D0D4DF;
	background: #1AA7EC;
	box-shadow: 0px -1px 0px 1px #D0D4DF inset;
	color: #313949;
}
.roles-tree-holder
{
	position: relative;
	top: 65px;
	left: 30px;

}