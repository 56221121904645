.email-integration-main-container
{
	width: 100%;
	background: #FFF;
	height: 100%;

}
.email-integration-title-holder
{
	display: flex;
	flex-direction: column;
	position: relative;
	top: 30px;
	left: 30px;
}
.email-integration-title
{
	
	color: #202123;
font-family: Inter;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.email-integration-subtitle
{
	color: #434D5F;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 21px;
}

.email-integration-popular-mail-text
{
	position: relative;
	top: 80px;
	left: 30px;
	color: #202123;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.email-integration-popular-mail-holder
{
	display: flex;
	position: relative;
	left: 30px;
	top: 90px;
	
	padding: 10px 122.4px 0px 0px;
	align-items: flex-start;
	gap: 30px;
}
.email-integration-mail-tile
{
	display: flex;
	width: 152px;
	height: 117px;
	padding: 20px 56.895px 20px 57.105px;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #D7E2ED;
}
.email-integration-mail-icon
{
	width: 80px;
	height: 55px;


}
.email-integration-mail-text
{
	color: #313949;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;


}
