
 @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600);
 *, *:before, *:after {
   box-sizing: border-box;
}

 body {
   background: #fff;
   font-family: 'Open Sans', Arial, sans-serif;
   font-size: 14px;
   line-height: 150%;
}
table, tr, td
{
  border-collapse: collapse;
  border:none;
}

 .truncate {
   display: inline-block;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 100%;
}

 .disclaimer {
   margin: 0 auto 50px;
   text-align: center;
   width: 400px;
}
 .btn {
   border: none;
   cursor: pointer;
   display: block;
   font-family: 'Open Sans', Arial, sans-serif;
   font-size: 16px;
   outline: none;
   padding: 15px;
   transition: all 0.1s linear;
}
 .wrapper {
   box-shadow: 0 4px 20px rgba(51, 51, 51, .2);
   margin: 50px auto;
   overflow: auto;
   width: 1024px;
}
 .wrapper .inbox-container {
   float: left;
   height: 500px;
   width: calc(100% - 200px);
}
 #sidebar {
   background: #009bd6;
   float: left;
   position: fixed;
   min-height: 700px;
   z-index: 1000;
   width: 200px;
}
 #sidebar .sidebar__inboxes {
   margin-top: 50px;
   padding: 0px;
}
 #sidebar .sidebar__inboxes .item-count {
   background: #00719c;
   border-radius: 5px;
   float: right;
   padding: 2px 8px;
   margin: -2px -8px;
}
 #sidebar .sidebar__inboxes li a {
   color: #fff;
   cursor: pointer;
   display: block;
   margin-bottom: 10px;
   padding: 15px;
   text-decoration: none;
   transition: background 0.1s linear;
   width: 100%;
}
 #sidebar .sidebar__inboxes li a:hover {
   background: #00719c;
}
 #sidebar .sidebar__inboxes .fa {
   margin-right: 0px;
}

 #sidebar .sidebar__compose {
   background: #34393d;
   cursor: pointer;
   display: flex;
   text-decoration: none;
   transition: background 0.1s linear;
   width: 100%;
   height: 60px;
   align-items: center;
   justify-content: center;
   color: #fff;
   padding: 20px 15px;
   text-align: center;
}
 
 #sidebar .btn.compose {
   color: #fff;
   padding: 20px 15px;
   text-align: center;
   text-decoration: none;
   transition: all 0.1s linear;
   width: 100%;
}
 #sidebar .btn.compose:hover {
   background-size: 150%;
}
 #sidebar .btn.compose:hover .fa {
   -webkit-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   transform: rotate(90deg);
}
 #sidebar .btn.compose .fa {
   margin-left: 10px;
   transition: all 0.1s linear;
}
 .email-list {
  background: #f5f5f5;
  float: left;
  height: 700px;
  max-height: 100%;
  position: relative;
  left: 200px;
  width: 86.716%;
  overflow: scroll; /* Enable scrolling */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

/* Hide scrollbar for Webkit browsers (e.g., Chrome, Safari) */
.email-list::-webkit-scrollbar {
  width: 0.4em; /* Adjust this width to your preference */
}

.email-list::-webkit-scrollbar-thumb {
  background-color: transparent; /* Scrollbar thumb color (transparent) */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.email-list::-webkit-scrollbar-track {
  background-color: transparent; /* Scrollbar track color (transparent) */
}

 .email-list.empty {
   color: #aaa;
   padding-top: 200px;
   text-align: center;
}
 .email-list .email-item {
   background: #fff;
   border-bottom: 1px solid #eee;
   cursor: pointer;
   padding: 10px 15px;
   position: relative;
}
 .email-list .email-item.selected {
   color: #009fc4;
}
 .email-list .email-item__subject {
   margin-bottom: 8px;
}
 .email-list .email-item__details {
   font-size: 12px;
   opacity: 0.5;
   text-transform: uppercase;
}
 .email-list .email-item__unread-dot {
   height: 100%;
   right: 10px;
   position: absolute;
   top: 10px;
}
 .email-list .email-item__unread-dot[data-read="false"]:before {
   background: #009fc4;
   border-radius: 50%;
   content: '';
   display: block;
   height: 6px;
   width: 6px;
}
 .email-list .email-item__time {
   float: right;
   text-align: right;
   width: 40%;
}
 .email-list .email-item__from.truncate {
   width: 60%;
}
 .email-list .email-item:hover:not(.selected) {
   background: #fafafa;
}
 .email-content {
   background: #fff;
   border-left: 1px solid #ddd;
   float: left;
   
   position: relative;
   left: 200px;
   display: block;
   clear: both;
   width:  86.716%;
}
 .email-content__header {
   background: #f5f5f5;
   border-bottom: 1px solid #eee;
   padding: 10px 15px;
}
 .email-content__subject {
   font-size: 18px;
   margin: 10px 0;
}
 .email-content__details {
   font-size: 12px;
   opacity: 0.5;
   text-transform: uppercase;
}
 .email-content__time {
   color: #878787;
   float: right;
}
 .email-content__from {
   color: #878787;
}
 .email-content__message {
   padding: 20px 15px 15px;
}
 .email-content .delete-btn {
   cursor: pointer;
   margin: -5px;
   padding: 5px;
   position: absolute;
   right: 20px;
   top: 24px;
   transition: color 0.1s linear;
}
 .email-content .delete-btn:hover {
   color: #e23e57;
}
 .footer {
   background: #f5f5f5;
   border-top: 1px solid #ddd;
   color: #999;
   clear: both;
   font-size: 14px;
   padding: 10px;
   text-align: center;
   width: 100%;
}
 .list-item {
  list-style-type: none; /* Remove bullet marker */
  /* Add other styling as needed */
}
.selected {
  background-color: #00719c; /* Set your desired background color for the selected section */
  /* Add other styling as needed */
}