.profile-main-container
{
	width: 100%;
	height: 100%;
}
.profile-title
{
	color: #202123;
	top: 29px;
	left: 30px;
	position: relative;
	
	font-size: 19px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.profile-subtitle
{
	color: #434D5F;
	top: 35px;
	display: flex;
	left: 30px;
	width: 800px;
	position: relative;
	
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
}

.profile-table
{
	position: relative;
	top: 60px;
	left: 2%;
	width: 90%;
height: 118px;
border-radius: 10px;
border: 1px solid #D7E2ED;
}
.table-profile {
	 width: 100%;
	height: 118px;
	  border-collapse: collapse;
	  border-radius: 10px;
	  border: 0px solid;
}

.th-profile {

  background: rgba(26, 167, 236, 0.1);
  border-radius: 6px 6px 0px 0px;
  border: 1px solid rgba(166, 166, 166, 0.4);
 
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  padding-left: 10px;
  height: 30px;
  
  }



.td-profile{
  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  background: #FFFFFF;
  border: 1px solid rgba(166, 166, 166, 0.4);
  border-radius: 6px 6px 0px 0px;
  padding-left: 10px;
  height: 30px;
}
.profile-table-column-black-text
{
	color: #202123;

font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.profile-table-column-blue-text
{color: #338CF0;

font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.profile-add-profile-btn
{
	display: inline-flex;
	left: 30px;
	top: 50px;
	position: relative;
	min-width: 31px;
	padding: 8px 21.3px 8px 21.5px;
	align-items: flex-start;
	border-radius: 6px;
	border: 1px solid #D0D4DF;
	background: #1AA7EC;
	box-shadow: 0px -1px 0px 1px #D0D4DF inset;
	color: #313949;
}