.custom-tab-panel-main-container
{
	background: #EDF0F4;
	height: 707px;
}

.custom-tab-panel-white-box
{
	height: 707px;
	width: auto;
padding-bottom: 0px;
align-items: flex-start;
border-radius: 12px;
border: 1px solid #D7E2ED;
background: #FFF;
position: relative;

}