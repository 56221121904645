.administrator-roles-main-container
{
	width: 100%;
	background: #FFF;
	height: 100%;


}

.administrator-roles-header
{
	display: flex;
	padding: 10px 20px 10.8px 20px;
	align-items: center;
	border-bottom: 0.8px solid #D7E2ED;
	background: #EFF1F5;
}

.administrator-roles-back-title-container
{
height: 31.6px;
gap: 10px;
display: flex;
}
.administrator-roles-grey-text
{
	color: #616E88;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.administrator-roles-black-text
{color: #313949;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;}

.administrator-roles-view-users-btn
{
	align-items: flex-start;
	justify-content: center;
	width: 15%;
	position: relative;
	left: 90%;
	display: flex;
	padding: 7.8px 21.61px 7.8px 21.8px;
	align-items: flex-start;
	border-radius: 6px;
	border: 1px solid #D0D4DF;
	background: linear-gradient(180deg, #FCFDFF 0%, #EAEEF5 100%);
	box-shadow: 0px -1px 0px 1px #D0D4DF inset;
	color: #313949;
	text-align: center;
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.administrator-roles-save-changes-btn
{
	align-items: flex-start;
	justify-content: center;
	width: 10%;
	position: relative;
	left: 70%;
	display: flex;
	padding: 7.8px 21.61px 7.8px 21.8px;
	align-items: flex-start;
	border-radius: 6px;
	border: 1px solid #D0D4DF;
	background: #1AA7EC;
	box-shadow: 0px -1px 0px 1px #D0D4DF inset;
	color: #313949;
	text-align: center;
	
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.administrator-roles-permissions-container
{
	position: relative;
	left: 35px;
	top: 30px;
	width: 100%;
	height:100%;
	background:  #FFF;
}
.administrator-roles-permissions-element
{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 810px;
	padding: 12.56px 207px 7.24px 0px;
	align-items: flex-start;
	border-bottom: 0.8px solid #EDF0F4;
}
.administrator-roles-title-text
{
	
	color: #202123;

font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.administrator-roles-permissions-element-span-div
{
	width: 245px;

	
}
.administrator-roles-permissions-element-switch-div
{
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 10px;
	width: 600px;
}
.administrator-permissions-roles-element-text
{
	
	color: #202123;
	margin-top: 8px;
	margin-right: 5px;
	min-width: 100px;
	max-width: 100px;
	
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.administrator-roles-permissions-switch-list-container
{
	display: flex;
	flex-direction: column;
	position: relative;
}

.administrator-roles-checklist-container
{
	display: flex;
	flex-direction: column;
	width: 104.13px;
	max-height: 442.4px;
	padding: 5px 0px 4.99px 0px;
	position: relative;
	gap: 10px;
	left: 65px;
	background: #FFF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	position: absolute;
	top: 100%;
	z-index: 1000;
}
.administrator-roles-checklist-element
{
	display: flex;
	flex-direction: row;
	gap: 5px;
	margin-left: 20px;
	align-items: center;

}

.administrator-roles-checklist-text
{
	color: #313949;

font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.administrator-roles-checklist-checkbox
{
	width: 15px;
height: 15px;
border-radius: 3px;
border: 1px solid
}